// MARK: - AUTH
export const auth: string = '/auth'
export const users: string = '/users'
export const authHealth = '/auth/health'

// MARK: - PRODUCTS
export const products: string = '/products'
export const productsId: Function = (id: string) :string => `${products}/${id}`

// MARK: - WORKSPACES
export const workspaces: string = '/workspaces'
export const workspacesId: Function = (id: string) :string => `${workspaces}/${id}`
export const workspacesIdProducts: Function = (id: string) :string => `${workspaces}/${id}/${products}`
export const workspacesIdUsers: Function =  (id: string) :string => `${workspaces}/${id}/${users}`
export const workspacesIdMembers: Function = (id: string) :string => `${workspaces}/${id}/members`


import {defineStore} from 'pinia';
import type {Integration, IntegrationsStateProps} from '@/types/integrations/IntegrationsType';
import {products, workspaces} from "~/types/api/Auth";
import {api, asterix} from "~/types/api";
import {useUserStore} from "~/stores/user";

export const useIntegrationsStore = defineStore({
    id: 'integrations',
    state: (): IntegrationsStateProps => ({
        integration: undefined,
        integrations: [],
        cart: [],
    }),
    getters: {
        getIntegration(): Integration {
            return this.integration;
        },
    },
    actions: {
        async fetchProducts() {
            try {
                const userStore = useUserStore()
                const workspaceId = userStore.selectedWorkspace.id;
                const headers = useRequestHeaders(["cookie"]) as HeadersInit;
                const {data}: any = await useFetch(api + products + asterix + `?workspaceId=${workspaceId}`, {headers});
                this.integrations = data.value?.data;
            } catch (error) {
                console.log(error);
            }
        },
        async integrateIntoCurrentWorkspace() {
            if (!this.integration) return;
            try {
                const userStore = useUserStore()
                const workspaceId = userStore.selectedWorkspace.id;
                const productId = this.integration.id;
                const headers = useRequestHeaders(["cookie"]) as HeadersInit;
                await useFetch(`${api}/auth${workspaces}/${workspaceId}${products}/${productId}`, {
                    headers: {"x-workspace-id": workspaceId, ...headers},
                    method: 'POST',
                });

            } catch (error) {
                console.log(error);
            }
        },
        setIntegration(integration: any) {
            this.integration = integration;
        },
        setIntegrationViaKey(key: string) {
            if (!this.integrations || !this.integrations.length) return;
            this.integration = this.integrations.find((integration: any) => integration.key === key);
        }
    },
    persist: true,
});
